.nav-tenant-menu-container {
  margin-right: 8px;
}

.navbar-menu-font {
  color: #66717d !important;
  font-weight: 500 !important;
  font-size: 13px !important;
}

.tenant-menu-button {
  border: 1px solid #ddd !important;
  padding: 3px 8px !important;
  border-radius: 7px !important;
}

.generic-table-column {
  max-width: 250px;
  border-bottom: none !important;
}

.tenant-menu-button:hover {
  background-color: transparent !important;
}

.navbar-tenant-list {
  padding-left: 8px;
}

.navbar-user-name {
  padding-left: 8px;
}

.table-spinning-loader {
  position: absolute;
  background-color: #dddddd24;
}

.attendee-list-item {
  display: flex;
  align-items: center;
  padding: 8px 0;
  border-bottom: 1px solid #ddd;
  cursor: pointer;
}

.attendee-list-item:hover {
  background-color: #f5f5f5;
}

.tenant-list-text {
  overflow: hidden;
  max-width: 110px;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 13px;
}

.status-card {
  width: 300px;
  height: auto;
  background-color: #fff !important;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  border-radius: 4px !important;
  box-shadow: 9px 11px 25px -20px rgba(0, 0, 0, 0.49) !important;
  -webkit-box-shadow: 9px 11px 25px -20px rgba(0, 0, 0, 0.49) !important;
  -moz-box-shadow: 9px 11px 25px -20px rgba(0, 0, 0, 0.49) !important;
}

.survey-card-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 22px 22px 12px;
}

.disable-content {
  opacity: 0.5;
  pointer-events: none;
}

@media (max-width: 900px) {
  .navbar-tenant-list {
    margin: 0;
    display: none;
  }

  .nav-tenant-menu-container {
    margin-right: 0 !important;
  }

  .navbar-user-name {
    display: none;
  }
}