.settings-wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: #fff;
}

.settings-dashboard-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1 !important;
  position: relative !important;
  padding-left: 12px;
  background-color: #fff;
}

.settings-flex-container {
  align-items: flex-start !important;
  background-color: transparent !important;
  overflow: hidden !important;
  padding: 0 !important;
}

.settings-table {
  background-color: #fff;
  border-radius: 4px;
  padding: 20px 20px 8px 20px !important;
  display: flex !important;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 4px;
}

.settings-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 24px;
  margin-top: 1.5rem;
}

.settings-menu-button {
  display: none;
}

.settings-table-actions {
  text-align: end !important;
}

.settings-table-header th {
  border-bottom: 1px solid #ddd !important;
}

.header-menu-container {
  display: flex;
  justify-content: row;
  flex-grow: 2;
}

.settings-menu-container {
  display: none;
}

.sticky-container {
  height: fit-content;
  position: sticky;
  top: 25px;
}

.highlight {
  background: linear-gradient(
    to left,
    rgba(0, 0, 0, 0.2) 0px,
    rgba(0, 0, 0, 0.2) 1px,
    rgba(0, 0, 0, 0.1) 1px,
    rgba(0, 0, 0, 0) 100%
  );
  bottom: 0px;
  right: -1px;
  pointer-events: none;
  position: absolute;
  opacity: 0.5;
  top: 0px;
  transition-duration: 0.22s;
  transition-property: left, opacity, width;
  transition-timing-function: cubic-bezier(0.2, 0, 0, 1);
  width: 3px;
}

.settings-toggle-container {
  position: absolute !important;
  z-index: 3;
  margin-right: -14px !important;
  height: 100%;
  width: 32px;
  right: -7px;
}

.settings-toggle-button {
  position: sticky !important;
  top: 30px;
  padding: 3px !important;
  align-self: flex-end;
  background-color: #fff !important;
  box-shadow: rgba(9, 30, 66, 0.08) 0px 0px 0px 1px,
    rgba(9, 30, 66, 0.08) 0px 2px 4px 1px;
  color: #808080 !important;
}

.settings-toggle-button:hover {
  background-color: #0077b6 !important;
  color: #fff !important;
}

.full-width-column {
  max-width: none !important;
}

.options-container:hover .settings-toggle-button {
  display: -webkit-inline-flex !important;
}
.options-container:hover .highlight {
  opacity: 1 !important;
}

.options-container {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 13%;
  transition: width 0.4s;
  padding-left: 10px;
  background-color: #f1f4f9;
}

.options-hidden {
  width: 12px !important;
}

.options {
  min-width: 10% !important;
  width: 100% !important;
}

.settings-option {
  width: -webkit-fill-available !important;
  margin-bottom: 0;
  margin: 0 8px !important;
  padding: 10px 13px !important;
  border-bottom: none !important;
}

.settings-option:hover {
  background-color: #ebecf0 !important;
}

.settings-option:active {
  background-color: #004e7c23 !important;
}

.settings-option:active p {
  color: #004e7c !important;
}

.settings-option:active svg {
  color: #004e7c !important;
}

.header-breadcrumbs {
  display: none;
}

.selected-text {
  font-size: 24px !important;
  font-weight: 500 !important;
}

.disable-content {
  opacity: 0.5;
  pointer-events: none;
}

@media (max-width: 900px) {
  .settings-dashboard-container {
    width: 100vw;
    padding-left: 0;
  }

  .settings-flex-container {
    width: 100% !important;
    padding-left: 0 !important;
  }

  .settings-header {
    display: flex;
    flex-direction: column !important;
    align-items: flex-start !important;
    padding: 10px 10px;
  }

  .settings-header button {
    margin: 0;
  }

  .options-menu {
    margin-bottom: 1rem;
  }

  .settings-header-options {
    width: 100% !important;
  }

  .options-container {
    display: none !important;
  }

  .header-breadcrumbs {
    display: flex !important;
    flex-direction: row-reverse !important;
    align-items: center !important;
  }

  .selected-text {
    display: none;
  }

  .options {
    display: none !important;
  }

  .header-menu-container {
    flex-grow: 1.5;
    margin-bottom: 1rem;
  }

  .settings-menu-button {
    display: inline-flex;
  }

  .settings-menu-container {
    display: inline-flex;
  }
}

@media (min-height: 900px) and (min-width: 900px) {
  .settings-wrapper {
    height: 100%;
  }
}
