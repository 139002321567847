.login-form-box {
  display: flex;
  flex-direction: column;
  padding: 150px 70px;
  border-radius: 10px 0 0 10px;
  width: 350px;
  background-color: white;
  padding-top: 0px;
}

.login-form-container {
  align-items: center !important;
}

.login-form {
  display: flex;
  flex-direction: column;
}

.login-button {
  align-self: flex-end;
}

.forgot-password {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

@media (min-width: 600px) {
  .login-container {
    padding: 0 !important;
  }
}

@media (max-width: 850px) {
  .login-message {
    display: none !important;
  }

  .login-form-container {
    width: 100% !important;
    justify-content: center !important;
    align-items: flex-start !important;
  }

  .login-container {
    padding: 0 !important;
  }

  .login-button {
    margin-bottom: 2rem;
  }

  .login-form-box {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .login-button {
    margin-bottom: 2rem !important;
  }
}
/* Hide the Microsoft Edge eye icon */
input[type="password"]::-ms-reveal {
  display: none;
}

/* Show a custom icon or placeholder to indicate password visibility */
input[type="password"]::-ms-clear {
  display: none;
}