.profile-dashboard-container {
  flex-grow: 1 !important;
  position: relative !important;
}

.profile-flex-container {
  height: 75vh !important;
  align-items: flex-start !important;
}

.profile-menu-container {
  display: none;
}

.profile-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 24px;
  margin-top: 1rem;
}

.profile-menu-button {
  display: none;
}

.header-menu-container {
  display: flex;
  justify-content: row;
  flex-grow: 0 !important;
  margin-right: 0.75rem;
}

.profile-menu-container {
  display: none;
}

.header-breadcrumbs {
  display: none;
}

@media (max-width: 900px) {
  .profile-dashboard-container {
    width: 100vw;
  }

  .profile-flex-container {
    width: 98% !important;
    padding-left: 0 !important;
  }

  .profile-menu-container {
    display: inline-flex;
  }

  .profile-header {
    display: flex;
    flex-direction: column !important;
    align-items: flex-start !important;
  }

  .options-menu {
    margin-bottom: 1rem;
  }

  .header-breadcrumbs {
    display: block;
  }

  .profile-header-options {
    width: 100% !important;
  }

  .options {
    display: none !important;
  }

  .header-menu-container {
    flex-grow: 1.5;
    margin-top: 10px;
    margin-bottom: 0;
  }

  .profile-menu-button {
    display: inline-flex;
  }

  .profile-menu-container {
    display: inline-flex;
  }
}
