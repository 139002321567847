.rbc-overlay > * + * {
  margin: 5px;
}

.rbc-time-view .rbc-header {
  border-bottom: none;
}

.rbc-selected-cell {
  background-color: #eaf6ff !important;
}

.rbc-slot-selection {
  background-color: #03b3ff2a;
  color: #2a2a2a;
}

.rbc-month-view {
  border: 1px solid #ddd;
  border-top: none;
}

.rbc-off-range-bg {
  background-color: inherit;
}

.rbc-off-range {
  color: #2a2a2a !important;
}

.rbc-time-gutter {
  width: 77.0469px;
}

.rbc-time-slot {
  text-align: center;
}

.rbc-label {
  color: #616161;
}

.rbc-time-view {
  border: none;
}

.rbc-time-view .rbc-button-link {
  width: 100%;
  padding-left: 8px;
}

.rbc-button-link {
  width: 100%;
  text-align: left;
  padding-left: 10px;
  padding-bottom: 5px;
}

.rbc-row-content {
  margin-top: 10px;
  margin-bottom: 5px;
}

.rbc-time-view .rbc-allday-cell {
  height: auto;
}

.rbc-time-view .rbc-row {
  min-height: auto;
}

.rbc-time-view .rbc-header + .rbc-header {
  border-left: 1px solid #ddd;
}

.rbc-header + .rbc-header {
  border-left: none;
}

.rbc-time-content {
  border-top: 1px solid #ddd;
  border-left: 1px dashed #ddd;
  border-right: 1px dashed #ddd;
}

.rbc-show-more {
  color: #03b2ff;
}

.rbc-time-header {
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
}

.rbc-time-view .rbc-time-header {
  min-height: 15px;
}

.rbc-timeslot-group {
  min-height: 50px !important;
  border-bottom: 1px dashed #ddd !important;
}

.rbc-day-slot.rbc-time-column
  > .rbc-timeslot-group
  > .rbc-time-slot:nth-child(2) {
  border-top: 1px dashed #ddd;
}

.rbc-day-slot.rbc-time-column
  > .rbc-timeslot-group
  > .rbc-time-slot:nth-child(1) {
  border-top: none;
}

.rbc-time-gutter.rbc-time-column
  > .rbc-timeslot-group
  > .rbc-time-slot:nth-child(2) {
  min-height: 25px;
  border-top: 1px dashed #ddd;
}
.rbc-day-slot .rbc-event-label {
  display: none;
}

.rbc-day-slot .rbc-event-content {
  display: flex;
}
