.session-terminate-container {
  background-color: #e76f51 !important;
  text-align: center !important;
  padding: 4px 0 !important;
  display: flex !important;
  justify-content: center !important;
  align-content: center !important;
}

.dashboard-action-container {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}

.dashboard-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 6px;
  background: white;
  padding: 8px;
  border-radius: 4px;
  padding-left: 1.5rem;
}

.session-action-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 80px;
  background-color: #fff !important;
  margin-bottom: 6px;
  border-radius: 4px;
  padding-left: 1.5rem;
}

.session-qr-code-container {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
}

.qr-code-button {
  width: auto !important;
  text-transform: none !important;
  flex-direction: column !important;
  border-radius: 0 !important;
  padding: 0.3rem 1rem !important;
  border: 1px solid #ddd !important;
  border-bottom: none !important;
  border-top: none !important;
}

.session-status-cards {
  display: flex !important;
  justify-content: flex-start !important;
  margin-bottom: 6px !important;
  width: 100% !important;
  background-color: #fff;
}

.session-client-info-btn {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 0 !important;
  padding: 0.3rem 1rem !important;
  border-right: 1px solid #ddd !important;
}

.session-card {
  margin-right: 1rem !important;
  width: 20% !important;
  box-shadow: none !important;
}

.button-wave-highlight {
  width: -webkit-fill-available !important;
  position: absolute !important;
  height: 60px !important;
}

@media (max-width: 1100px) {
  .session-card {
    margin-bottom: 6px !important;
    width: 33% !important;
  }

  .dashboard-header > .session-qr-code-container {
    display: none !important;
  }

  .dashboard-header {
    background-color: transparent;
  }

  .session-action-container {
    justify-content: flex-end;
  }
}
