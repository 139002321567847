.matrix-option-label {
  display: none;
}

.matrix-question {
  display: flex;
  width: 100%;
}

.matrix-question-title {
  width: 45%;
  display: flex;
  align-items: center;
}

.matrix-radio-group {
  display: flex;
  flex-direction: row !important;
  justify-content: space-evenly;
  font-size: 14px;
  width: 55%;
}

.matrix-clear-icon {
  position: absolute !important;
  right: -15px !important;
}

.matrix-clear-button {
  width: auto !important;
  align-self: flex-end !important;
  position: absolute !important;
  right: 0 !important;
  bottom: -20px !important;
  padding: 0 !important;
  display: none !important;
}
.matrix-clear-button-rtl {
  right: auto !important;
  left: 0 !important;
}

.matrix-clear-icon-rtl {
  right: auto !important;
  left: -15px !important;
}

.matrix-clear-button:hover {
  background-color: "transparent";
}

.matrix-error-message {
  position: absolute;
  right: 0;
  bottom: -15px;
}

@media (max-width: 1000px) {
  .survey-radio-input {
    font-size: 1.25rem !important;
  }
}

@media (max-width: 830px) {
  .survey-radio-group {
    flex-direction: column !important;
    align-items: flex-start !important;
  }
}

@media (max-width: 800px) {
  .matrix-option-label {
    display: block;
  }

  .matrix-options {
    display: none !important;
  }

  .matrix-question {
    flex-direction: column;
  }

  .matrix-question-title {
    width: 100%;
  }

  .matrix-radio-group {
    width: 100%;
    flex-direction: column !important;
    align-items: flex-start;
  }

  .matrix-clear-icon {
    display: none !important;
  }

  .matrix-clear-button {
    display: inline-flex !important;
  }
}

@media (max-width: 550px) {
  .matrix-radio-group {
    flex-direction: column !important;
  }

  .matrix-clear-button {
    bottom: 0 !important;
  }
}
