.account-container {
  width: 70%;
  padding: 1rem;
}

.divider {
  width: 100%;
  margin: 2rem 0;
}

@media (max-width: 900px) {
  .account-container {
    width: 100%;
  }

  .divider {
    width: 100%;
  }
}
