.survey-header-container {
  display: flex;
  justify-content: space-between;
}

.survey-header-info {
  display: flex;
  justify-content: space-between;
}

.reload-answers-button {
  position: absolute;
  bottom: 0;
  left: 0;
}

.banner-container {
  max-width: 853px !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
  background-color: #d62828;
  padding: 4px 0 !important;
  position: sticky;
  z-index: 2;
  top: 0;
}

.banner-text {
  font-size: 0.9rem !important;
  color: #fff;
  margin-right: 6px !important;
}

.reload-answers-button {
  font-size: 0.9rem !important;
  text-transform: none !important;
  font-weight: 400 !important;
  color: #fff !important;
  padding: 0 3px !important;
  text-decoration: underline !important;
}

.reload-answers-button:hover {
  text-decoration: underline !important;
}

@media (max-width: 800px) {
  .survey-header-container {
    flex-direction: column;
  }

  .survey-description {
    font-size: 11px !important;
    margin-left: 0 !important;
  }

  .survey-header-info {
    flex-direction: column;
  }

  .rules-switch {
    margin-left: -4px !important;
    margin-top: 6px;
    margin-right: 0;
  }

  .survey-lang-selector svg {
    font-size: 16px !important;
  }

  .lang-menu-container {
    position: absolute;
    top: 0;
    right: 0;
  }
}
