@media (max-width: 900px) {
  .sidebar-content {
    width: 90% !important;
  }
}

@media (max-width: 900px) {
  .sidebar {
    width: 100% !important;
    height: calc(100% - 42px) !important;
    top: 42px !important;
  }
}

@media (max-width: 600px) {
  .sidebar {
    height: calc(100% - 34px) !important;
    top: 34px !important;
  }
}
