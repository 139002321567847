.stats-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 3rem;
}

.stats-container table,
.stats-container tr,
.stats-container td,
.stats-container th {
  border: 1px solid rgba(224, 224, 224, 1);
}

.sticky-cell {
  position: sticky !important;
  top: 0 !important;
  left: -1px !important;
  background-color: #fff !important;
  font-weight: 600 !important;
}
