body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;
}

.hide-edge-icon input::-ms-reveal,
.hide-edge-icon input::-ms-clear {
  display: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  appearance: textfield;
  -moz-appearance: textfield;
}

.MuiDrawer-paper {
  width: 30% !important;
}

.MuiFormHelperText-root {
  margin-top: 0 !important;
  margin-left: 7px !important;
}

.app-container {
  display: flex !important;
  flex-direction: column;
  padding: 0 !important;
  flex-grow: 1;
  background-color: #f1f4f9;
}

@media (max-height: 600px) {
  .app-container {
    overflow-y: auto !important;
  }
}

html,
body,
#root,
#root > div {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

#root {
  background-color: white;
}

::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #a0a0a0;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.table-mb {
  margin-bottom: 8rem;
}

.project-version {
  position: absolute;
  right: 0.6rem;
  bottom: 0.05rem;
  z-index: 7;
  font-size: 12px !important;
  color: #000000a6;
}
