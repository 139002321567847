.permission-checkbox-container {
  display: flex !important;
  flex-direction: column !important;
}

.permission-form-group {
  flex-direction: row !important;
}

@media (max-width: 600px) {
  .permission-checkbox-container {
    flex-direction: row !important;
  }

  .permission-form-group {
    flex-direction: column !important;
  }
}
