.client-event-form-container {
  border-bottom: 1px solid #ddd;
  padding: 0 10px;
}

.client-slot-container {
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 0 10px;
  margin-bottom: 16px;
}

.full-height {
  height: calc(100vh - 64px) !important;
}

.process-cell {
  border: none !important;
}

.app-container {
  overflow-y: auto;
}

.client-form-container {
  background-color: transparent;
  padding-left: 0;
  padding-right: 0;
  padding-top: 1rem;
}

.client-list-actions {
  text-align: end !important;
}

.client-stepper-container {
  background-color: #fff;
  padding: 1.5rem 0.5rem 1rem 0.5rem;
  margin-bottom: 12px;
  border-radius: 4px;
}

@media (max-height: 820px) {
  .client-form-container {
    height: auto;
  }
}