.page-container {
  height: 100%;
  display: flex !important;
  justify-content: center;
}

.session-info-container {
  position: relative;
  width: 400px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  border-radius: 1rem;
  margin-top: 8rem;
  background-color: #fff;
  padding: 1rem;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 6px -1px,
    rgba(0, 0, 0, 0.14) 0px 2px 6px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
}

.session-stats {
  display: flex;
  align-items: center;
}

.session-event-list {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  padding: 0 6px;
  margin-top: 4;
  max-height: 120px;
  overflow-y: auto;
  overflow-x: hidden;
}

.session-event-item {
  width: 100%;
  padding-top: 4px;
}

.br-table {
  background-color: #fff;
  border-radius: 4px;
  padding: 20px 20px 8px 20px !important;
  display: flex !important;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 4px;
}

.session-events {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.code-container {
  display: flex;
  justify-content: space-between;
}

.code-input {
  width: 45px;
  height: 45px;
  padding: 0;
  font-size: 24px;
  text-align: center;
  margin-right: 12px;
  text-transform: uppercase;
  color: #494949;
  font-family: SF Pro Text, SF Pro Icons, Helvetica Neue, Helvetica, Arial,
    sans-serif;
  border: 1px solid #d6d6d6;
  border-radius: 4px;
  background: #fff;
  background-clip: padding-box;
}

.loading-text {
  font-weight: bold;
  display: inline-block;
  font-family: monospace;
  font-size: 30px;
  clip-path: inset(0 1ch 0 0);
  animation: l 1s steps(4) infinite;
}

@keyframes l {
  to {
    clip-path: inset(0 -1ch 0 0);
  }
}

.modal-blur {
  backdrop-filter: blur(1rem);
}

.code-error {
  border: 1px solid #f44336 !important;
}

.process-name {
  display: flex;
  justify-content: center;
  width: 100%;
}

.session-date {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.session-actions {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  align-items: center;
  margin-top: 3rem;
}

.agreement-content {
  border-bottom: 2px solid #ddd;
  padding-bottom: 10px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.agreement-description {
  padding: 0 1rem;
  text-align: justify;
}

.signature-wrapper {
  border: 1px solid #ddd;
}

.agreement-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(-360deg);
  }
}

.overview-container {
  height: 100%;
  display: flex !important;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 !important;
}

.overview-alert {
  background-color: #52b2ff85;
  padding: 1rem;
  border-radius: 4px;
  border: 2px solid #52b2ff;
}

.critical-alert {
  background-color: #d62828ad;
  border: 2px solid #d62828;
}

.survey-data-container {
  height: inherit;
  display: flex !important;
  flex-direction: row;
  justify-content: flex-start;
}

.completed-container {
  justify-content: center;
  min-height: 20rem;
}

.client-info-container {
  width: 25%;
  height: 100%;
  padding: 0;
  position: relative;
  transition: width 0.4s;
  margin-right: 4px;
}

.waiting-text {
  flex-grow: 1 !important;
  justify-content: flex-end !important;
}

.client-hidden {
  width: 1% !important;
}

.client-toggle-button {
  position: absolute !important;
  top: 20px !important;
  right: -10px !important;
  padding: 0 !important;
  z-index: 3;
}

.table-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-right: 8px;
  margin-top: 4px;
  margin-left: 8px;
}

.user-card {
  background-color: #fff !important;
  width: 100%;
  height: 100%;
  border-radius: 0 !important;
  box-shadow: none !important;
  /* for Internet Explorer, Edge */
  -ms-overflow-style: none;
  /* for Firefox */
  scrollbar-width: none;
}

.user-card::-webkit-scrollbar {
  /* for Chrome, Safari, and Opera */
  display: none;
}

.survey-table-container {
  background-color: #fff;
  border-radius: 4px;
  padding: 20px 20px 8px 20px !important;
  display: flex !important;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 4px;
  box-shadow: 9px 11px 25px -20px rgba(0, 0, 0, 0.49) !important;
  -webkit-box-shadow: 9px 11px 25px -20px rgba(0, 0, 0, 0.49) !important;
  -moz-box-shadow: 9px 11px 25px -20px rgba(0, 0, 0, 0.49) !important;
}

.session-survey-cell {
  border-bottom: none !important;
}

.session-survey-table {
  flex-grow: 1;
  border-radius: 4px;
  box-shadow: none !important;
}

.survey-table-container-data {
  border-bottom: 1px solid #ddd;
}

.survey-table-container-data::-webkit-scrollbar:vertical {
  display: none;
}

.session-status-card {
  border-radius: 4px !important;
  box-shadow: none !important;
}

.survey-header tr th {
  background-color: #fff !important;
}

.survey-footer {
  border-top: 1px solid #ddd;
}

.survey-table-header th {
  background-color: #fff !important;
  border-bottom: 1px solid #ddd !important;
}

tr.survey-row:last-of-type td {
  font-weight: bold;
  border-bottom: none;
}

.survey-row:hover {
  background-color: #f4f5f7;
}

.survey-completed-col {
  width: 20px !important;
}

@media (max-width: 1100px) {
  .survey-data-container {
    flex-direction: column;
  }

  .survey-data-container>.client-info-container {
    display: none;
  }

  .client-info-container {
    height: 100vh;
    width: auto;
  }

  .settings-toggle-container {
    display: none;
  }

  .user-card {
    max-width: none;
  }

  .table-container {
    width: auto;
  }
}