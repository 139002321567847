.survey-card {
  max-width: 150px;
  width: 150px;
  height: 200px;
  margin-right: 1rem;
  background-color: #ffffff !important;
}

.process-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.create-card {
  min-width: 150px;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed darkgray;
  box-shadow: none !important;
  cursor: pointer;
}

.survey-title {
  font-size: 13px !important;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.screening-tab-list {
  border-bottom: 2px solid #ddd;
}

.screening-tab {
  min-height: auto !important;
  padding: 0.6rem 1rem !important;
  border-radius: 2rem !important;
}

.active-tab {
  background-color: #03b2ff2b !important;
}

.process-select-container {
  position: absolute;
  display: flex;
  align-self: flex-end;
  margin-right: 24px;
}

@media (min-height: 820px) {
  .screening-container {
    overflow-y: auto;
  }
}

@media (min-height: 800px) {
  .screening-container {
    padding-right: 8px !important;
    padding-left: 8px !important;
  }
}
