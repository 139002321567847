.client-dashboard {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}

.client-dashboard-header {
  width: 97%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 24px;
  margin-top: 1.5rem;
}

.header-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 420px) {
  .header-container {
    flex-direction: column-reverse;
  }
}

.list-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow-y: auto;
  overflow-x: hidden;
}

.list-item-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 92%;
  height: auto !important;
  background-color: white;
  margin-bottom: 15px;
  padding: 15px 25px;
  border-radius: .5rem;
  border: 1px solid #e9e9e9;
  cursor: pointer;
}

.item-info-container {
  margin-left: 10px;
  display: flex;
  flex-direction: column;
}

.roles-cell {
  overflow: auto !important;
  white-space: wrap !important;
}

.client-avatar {
  width: 35px !important;
  height: 35px !important;
  background-color: #03b2ff !important;
  border: 1px solid #e9e9e9 !important;
}

.column-header-padding {
  padding-left: 20px !important;
  padding-right: 15px !important;
}

.MuiDataGrid-menuList {
  min-width: 180px !important;
}

.MuiDataGrid-menuList .MuiTypography-root.MuiTypography-body1.MuiListItemText-primary {
  font-size: 13px !important;
}

.MuiDataGrid-menuList .MuiSvgIcon-root.MuiSvgIcon-fontSizeSmall {
  font-size: 16px !important;
}

.MuiTablePagination-menuItem {
  font-size: 13px !important;
}

.MuiDataGrid-filterForm .MuiInputBase-input {
  font-size: 14px !important;
}

.MuiDataGrid-panelHeader .MuiFormLabel-root {
  font-size: 14px !important;
}

.MuiDataGrid-panelHeader .MuiInputBase-root {
  font-size: 14px !important;
}

.MuiDataGrid-columnsPanelRow .MuiTypography-root {
  font-size: 13px !important;
}

.MuiDataGrid-panelFooter .MuiButtonBase-root {
  font-size: 13px !important;
}

.MuiDataGrid-panelHeader .MuiTypography-root {
  font-size: 13px !important;
}

.no-padding {
  padding: 0px !important;
}

@media (max-width: 600px) {
  .MuiDataGrid-virtualScroller {
    overflow-x: auto !important;
  }
}

.search-bar-style {
  padding: 2px 4px;
  display: flex;
  align-items: center;
  background-color: white !important;
  border: 1px solid #ced4da;
  box-shadow: none !important;
  width: 100%;
  margin-right: 15px;
  height: 2rem;
}

.search-bar-container {
  display: "flex";
  flex-direction: "row";
  flex-grow: 2;
}

@media (max-width: 420px) {
  .search-bar-style {
    margin-right: 0px;
  }

  .media-button {
    margin-bottom: 12px !important;
    width: 10rem !important;
  }

  .search-bar-container {
    width: 90%;
  }
}

.details-dashboard-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100vh;
  width: 100%;
  overflow-x: hidden;
}

.details-header-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  min-height: 60px;
}

.details-back-link {
  position: absolute;
  left: 0;
  top: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 28px;
  cursor: pointer;
}

.details-back-link:hover {
  color: #03b2ff;
}

.client-details-info {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.client-profile-details {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  background-color: white;
  padding: 30px;
  padding-bottom: 0px;
}

.client-avatar-details {
  height: 50px !important;
  width: 50px !important;
}

.tabs-container {
  width: 100%;
  padding-left: 30px;
  background-color: white;
}

.tabs-component {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
}

.details-tab-item {
  color: #2a2a2a !important;
  min-width: 80px;
  width: 10rem;
  min-height: 20px !important;
  height: 35px !important;
}

.active-tab {
  background-color: #f1f4f9 !important;
  border-radius: 0.7rem 0.7rem 0 0 !important;
  padding: 10px 20px !important;
  border-bottom: none !important;
  cursor: pointer !important;
}

.client-data-container {
  height: 100%;
  width: 100%;
  background-color: #f1f4f9;
  padding-left: 30px;
  padding-bottom: 0;
  margin-top: 1rem;
  flex-grow: 1;
}

.client-personal-info {
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.about-information {
  margin-bottom: 1.5rem;
}

.timeline-flag {
  width: 4px;
  height: 97px;
  font-size: 64px;
  margin: 0px;
  padding: 0px 5px 0px 0px;
  background-color: #03b2ff;
  border-radius: 1rem 0 0 1rem;
}

.timeline-view {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.timeline-container {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 0 3rem 0 2rem;
  max-height: 60vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.timeline-filter-item {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  padding-left: 0 !important;
}

.timeline-content-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 1rem;
}

.timeline-content {
  display: flex;
  align-items: center;
  padding: 15px 35px 15px 15px;
  width: 92%;
  border-radius: 1rem;
}

.timeline-activity-container {
  width: 75%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  min-height: 64px;
  display: flex;
  align-items: start;
  background-color: #fff;
  border-radius: .5rem;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.49) !important;
  -webkit-box-shadow: 3px 3px 25px -20px rgba(0, 0, 0, 0.49) !important;
  -moz-box-shadow: 9px 11px 25px -20px rgba(0, 0, 0, 0.49) !important;
}

.activity-info {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: 10px;
  padding: 25px;
}

.activity-desc {
  margin-left: 15px;
}

.activity-options {
  margin-left: auto;
}

.about-container {
  padding-right: 50px;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  overflow-y: scroll;
  margin-bottom: 20px;
  gap: 20px;
}

.general-information-container {
  height: 35vh;
  overflow-y: auto;
  width: 20%;
}

.general-information {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow-y: auto;
  padding: 15px;
  align-items: flex-start;
  background-color: #fff;
}

.general-information-header {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.session-container {
  width: 44%;
  height: 35vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: .5rem;
  overflow-y: auto;
  background-color: #fff;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.49) !important;
  -webkit-box-shadow: 3px 3px 25px -20px rgba(0, 0, 0, 0.49) !important;
  -moz-box-shadow: 9px 11px 25px -20px rgba(0, 0, 0, 0.49) !important;
}

.session-header {
  padding-left: 25px;
  padding-top: 20px;
}

.schedule-container {
  width: 30%;
  display: flex;
  flex-direction: column;
}

.client-event-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: #fff;
  border-radius: .5rem;
  padding: 25px;
  height: 140px;
  overflow-y: auto;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.49) !important;
  -webkit-box-shadow: 3px 3px 25px -20px rgba(0, 0, 0, 0.49) !important;
  -moz-box-shadow: 9px 11px 25px -20px rgba(0, 0, 0, 0.49) !important;
}

.client-event-item {
  width: 100%;
  height: 100%;
  overflow-x: clip;
}

.helper-list-container {
  width: 100%;
  background-color: #fff;
  padding: 25px;
  border-radius: .5rem;
  margin-top: 20px;
  height: 120px;
  overflow-y: auto;
  overflow-x: hidden;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.49) !important;
  -webkit-box-shadow: 3px 3px 25px -20px rgba(0, 0, 0, 0.49) !important;
  -moz-box-shadow: 9px 11px 25px -20px rgba(0, 0, 0, 0.49) !important;
}

.helper-list {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: baseline;
  flex-wrap: wrap;
}

.helper-info {
  width: 100px;
  height: 100px;
  border-radius: .5rem;
  background-color: aquamarine;
  margin: 10px;
}

.filter-button-text {
  font-weight: 400 !important;
  color: #000000 !important;
}

.filter-button-text.MuiButton-contained {
  font-weight: 400 !important;
  color: #ffffff !important;
}

.disable-effects-button {
  pointer-events: none;
  cursor: default;
}

.todo-list-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 1rem;
}

.todo-list-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 1rem;
}

.todo-header-title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.todo-list-actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.todo-list-filter {
  display: flex;
  align-items: flex-end;
  margin-bottom: 1rem;
}

.status-filter-selected-label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
}

.todo-list-items {
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.todo-item-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 98%;
  min-height: 45px;
  padding: 8px 15px;
  background-color: white;
  border-bottom: 1px solid #ddd;
}

.todo-item-info {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 60%;
}

.todo-item-title {
  font-size: 14px !important;
  font-weight: 500;
  padding-left: 10px;
  color: #2a2a2a;
}

.todo-item-date {
  font-size: 13px !important;
  font-weight: 400;
  color: #0000006b;
}

.todo-item-container:hover {
  background-color: #3434340a;
  cursor: pointer;
}

@media (min-height: 820px) {
  .list-container {
    margin-bottom: 4rem;
  }

  .todo-list-items {
    max-height: 60vh;
  }
}

@media (max-width: 920px) {
  .about-container {
    flex-direction: column;
    height: auto;
    padding-right: 0;
  }

  .client-data-container {
    box-sizing: border-box;
    overflow: auto;
  }

  .session-container {
    width: 100%;
  }

  .general-information-container {
    width: 100%;
  }

  .schedule-container {
    width: 100%;
    padding-top: 20px;
  }

  .client-event-container {
    box-sizing: border-box;
  }

  .helper-list-container {
    box-sizing: border-box;
  }
}

@media (min-width: 930px) and (max-width: 1490px) {
  .about-container {
    flex-direction: row;
    height: auto;
    padding-right: 0;
    flex-wrap: wrap;
  }

  .client-data-container {
    box-sizing: border-box;
    overflow: auto;
    display: flex;
    flex-wrap: wrap;
  }

  .session-container {
    width: 48%;
  }

  .general-information-container {
    width: 48%;
  }

  .schedule-container {
    width: 100%;
    flex-direction: row;
    display: flex;
    gap: 20px;
    height: 200px;
  }

  .client-event-container {
    margin: 20px 0px 20px 0px;
    box-sizing: border-box;
    width: 48%;
  }

  .helper-list-container {
    margin: 20px 0px 60px 0px;
    box-sizing: border-box;
    width: 48%;
    height: 140px;
  }
}

@media (min-width: 1490px) {
  .schedule-container {
    height: 35vh;
  }

}