.step-label {
  margin-top: 0px !important;
}

.active-process-card {
  width: 140px;
  height: 70px;
}

.process-data-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 32px;
}

.process-survey-table {
  width: 30%;
  margin-bottom: 1rem;
}

.process-session-dashboard {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1rem;
  width: 69%;
}

.process-table {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.filter-button {
  padding: 4px 6px !important;
  border-radius: 0 !important;
  display: flex !important;
  justify-content: space-between !important;
  text-transform: none !important;
  font-weight: 400 !important;
  font-size: 13px !important;
}

.filter-badge {
  width: 17px !important;
  height: 17px !important;
  border-radius: 50% !important;
  margin-top: 1px !important;
  background-color: #03b2ff !important;
  font-size: 10px !important;
  color: #fff !important;
  margin-left: 5px !important;
  margin-right: 6px !important;
  font-weight: 600 !important;
}

.filter-menu-container {
  width: 100% !important;
  min-width: 212px !important;
  display: flex !important;
  justify-content: space-between !important;
  padding: 12px 0 !important;
  background-color: #fff !important;
  border-bottom: 1px solid #ddd !important;
  min-height: 34px !important;
}

.filter-menu-item {
  font-size: 13px !important;
  margin: 6px !important;
  background-color: #fff !important;
  border-radius: 4px !important;
}

.filter-clear-button {
  font-size: 11px !important;
  width: 55px !important;
  min-width: 55px !important;
  height: 100% !important;
  padding: 0.2rem !important;
  border-radius: 0 !important;
  border: 1px solid !important;
  margin-right: 6px !important;
}

.selected-filters-label {
  max-width: 140px !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  color: #666 !important;
  margin-left: 8px !important;
  font-size: 10px !important;
}

.process-cards {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
}

.process-card {
  width: 45%;
}

.process-title {
  display: flex;
  align-items: center;
}

.filter-menu-item:hover {
  box-shadow: -2px 2px 2px 0 rgba(45, 45, 45, 9%);
}

@media (max-width: 800px) {
  .process-data-container {
    flex-direction: column;
  }

  .process-overview {
    padding: 0 !important;
  }

  .process-title {
    margin-top: 3.5rem;
  }

  .process-stepper {
    overflow-x: auto;
  }

  .process-survey-table {
    width: 100%;
  }

  .process-session-dashboard {
    width: 100%;
  }

  .process-table {
    width: 100%;
  }

  .process-cards {
    flex-wrap: wrap;
  }

  .process-card {
    width: 100%;
  }

  .process-card:first-child {
    margin-right: 0 !important;
    margin-bottom: 1rem;
  }

  .MuiTablePagination-selectLabel {
    display: none;
  }

  .MuiTablePagination-toolbar {
    padding: 0 !important;
  }
}
