.templates-container {
    display: flex;
    flex: 1;
    flex-direction: row;
    background-color: '#ffffff';
}

.templates-first-menu-content {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
}

.templates-second-menu-content {
    display: flex;
    flex-direction: column;
}

.templates-preview-content {
    display: flex;
    background-color: #f1f4f9;
    width: 100%;
    height: 100%;
}

.vertical-divider {
    border-right: 2px solid #e2e2e2;
}

.editor-input {
    min-height: 150px;
    border: 1px solid #ccc;
    padding: 10px;
}

.editor-placeholder {
    color: #aaa;
    position: absolute;
    top: 10px;
    left: 10px;
}

.toolbar {
    display: flex;
    gap: 10px;
    margin-bottom: 10px;
}

.toolbar button {
    padding: 5px 10px;
    cursor: pointer;
}

.templates-list-content {
    max-height: 91vh;
    max-width: 16em;
    width: 100%;
}

.template-instances-list-content {
    max-height: 86vh;
    max-width: 16em;
    width: 100%;
}

.list-item-button {
    border-bottom: 1px solid #f0f0f0 !important;
}

.list-item-button:hover {
    background-color: #f1f4f9 !important;
    cursor: pointer;
}

.list-item-button.Mui-selected {
    color: #004E7C !important;
    border-bottom: 2px solid #004E7C !important;
    background-color: #f1f4f9 !important;
}

@media (max-width: 770px) {
    .templates-container {
        flex-direction: column !important;
    }

    .templates-list-content {
        max-height: 50vh;
        max-width: none;
        width: 100%;
    }

    .template-instances-list-content {
        max-height: 50vh;
        max-width: none;
        width: 100%;
    }

    .vertical-divider {
        border-right: none;
        width: 100%;
        height: fit-content;
    }

    .templates-first-menu-content {
        border-bottom: 5px solid #e2e2e2;
    }
}