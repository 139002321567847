.navbar-menu-container {
  display: none;
}

.module-options {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
}

.lang-img {
  width: 18px;
  height: 18px;
}

.lang-img-sm {
  width: 17px;
  height: 17px;
  margin-right: 6px;
}

.lang-img-item {
  font-size: 0.75rem !important;
  padding: 6px 12px !important;
}

.lang-img-item:hover {
  background-color: #dddddd52 !important;
}

@media (max-width: 1000px) {
  .navbar-menu-container {
    display: flex;
    flex-grow: 1;
  }

  .module-options {
    display: none;
  }

  .module-menu-options {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  .toolbar-container {
    justify-content: flex-end;
  }

  .selected-institution-label {
    display: none !important;
  }
}
