.creator-container {
  width: 100%;
  height: 100%;
}

.full-width {
  width: 100%;
}

.hidden {
  display: none !important;
}

.question-container {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: flex-start;
  min-height: 250px;
  border-radius: 0.5rem 1rem 1rem 0.5rem;
  background-color: #fff;
  border: 1px solid #dadce0;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2),
    0 1px 1px 0 rgba(0, 0, 0, 0.141), 0 1px 3px 0 rgba(0, 0, 0, 0.122);
  margin: 15px 0;
}

.preview-container {
  box-shadow: none;
  min-height: auto;
}

.field-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.question-field-container {
  width: 100%;
  height: 100%;
  margin: 1.5rem 2rem;
}

.question-field-creator-container {
  width: 100%;
  height: 100%;
  margin-left: 2rem;
  margin-top: 1.5rem;
  margin-right: 2rem;
}

.field-select-flag {
  position: absolute;
  width: 6px;
  height: calc(100% + 2px);
  padding-right: 10px;
  left: 0;
  top: -1px;
  display: flex;
}

.flag-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.field-header-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 1.4rem;
}

.header-container-preview {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 1rem;
}

.field-header-menu-item {
  display: flex;
  align-items: center;
}

.title-control {
  width: 60%;
}

.type-control {
  width: 30%;
}

.field-footer-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.question-flag {
  background-color: #4285f4;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  flex-grow: 1;
  position: relative;
}

.question-footer {
  width: 100%;
  min-height: 40px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-top: 1px solid #dadce0;
  padding-top: 15px;
  margin-top: 25px;
}
